<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Industry</label>
                <Dropdown id="industry_id" dataKey="industry_id" v-model="forms.industry_id" :loading="loadingDropdownIndustry"
                    :options="dataDropdownIndustry" :class="{ 'p-invalid': errorAdd.industry_id }"
                    optionLabel="industry_name" optionValue="industry_id" placeholder="Pilih Industry" :filter="true"
                    :showClear="true" @filter="searchDropdownIndustry($event, 'add')"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.industry_id">{{
                    errorAdd.industry_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownIndustry: false,

            // dataDropdown,
            dataDropdownIndustry: null,

            // addNew
            forms: {
                industry_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownIndustry('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownIndustry(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dindustry.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownIndustry = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-industry',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownIndustry = res.data.data;
                        this.loadingDropdownIndustry = false;
                    } else if (purpose == null) {
                        this.dataDropdownIndustry = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-industry/create',
                data: {
                    "industry_id": this.forms.industry_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();

                    this.$store.dispatch("auth/getUserData");
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data ? err.response.data.data : {});
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.$store.commit('setErrorAdd', {});
            this.forms.industry_id = null;
        },
    }
}
</script>